import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-center py-3" }
const _hoisted_2 = { class: "btn-toolbar" }
const _hoisted_3 = {
  key: 0,
  class: "table-settings mb-4"
}
const _hoisted_4 = { class: "row align-items-center justify-content-between" }
const _hoisted_5 = { class: "col col-md-6 col-lg-3 col-xl-4" }
const _hoisted_6 = { class: "input-group" }
const _hoisted_7 = { class: "col-4 col-md-2 col-xl-1 pl-md-0" }
const _hoisted_8 = { class: "d-flex" }
const _hoisted_9 = { class: "card card-body border-light shadow-sm table-wrapper table-responsive pt-0" }
const _hoisted_10 = { class: "table table-hover min-h-400" }
const _hoisted_11 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountSelector = _resolveComponent("CountSelector")!
  const _component_DataFilterSelector = _resolveComponent("DataFilterSelector")!
  const _component_UserRow = _resolveComponent("UserRow")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_HourglassLoader = _resolveComponent("HourglassLoader")!

  return (_openBlock(), _createBlock(_component_HourglassLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      _createElementVNode("section", null, [
        _createElementVNode("div", _hoisted_1, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "d-block" }, [
            _createElementVNode("h2", { class: "h4" }, "Registered Users"),
            _createElementVNode("p", { class: "mb-0" }, "Below are the registered users")
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-sm btn-outline-primary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.RefreshData && _ctx.RefreshData(...args)))
            }, " Reload ")
          ])
        ]),
        (_ctx.data?.meta)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("form", {
                    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.RefreshData && _ctx.RefreshData(...args)), ["prevent"]))
                  }, [
                    _createElementVNode("div", _hoisted_6, [
                      _cache[7] || (_cache[7] = _createElementVNode("span", { class: "input-group-text" }, [
                        _createElementVNode("span", { class: "fas fa-search" })
                      ], -1)),
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "form-control px-1",
                        placeholder: "Find User",
                        "aria-label": "Find User",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.meta.search) = $event))
                      }, null, 512), [
                        [_vModelText, _ctx.data.meta.search]
                      ])
                    ])
                  ], 32)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_CountSelector, {
                      class: "me-2",
                      modelValue: _ctx.data.meta.per_page,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.meta.per_page) = $event)),
                      onUpdated: _ctx.RefreshData
                    }, null, 8, ["modelValue", "onUpdated"]),
                    _createVNode(_component_DataFilterSelector, {
                      modelValue: _ctx.type,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.type) = $event)),
                      filters: _ctx.filters,
                      onUpdated: _ctx.RefreshData
                    }, null, 8, ["modelValue", "filters", "onUpdated"])
                  ])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("table", _hoisted_10, [
            _cache[8] || (_cache[8] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "#"),
                _createElementVNode("th", null, "Name"),
                _createElementVNode("th", null, "Username"),
                _createElementVNode("th", null, "Email"),
                _createElementVNode("th", null, "Is Banned"),
                _createElementVNode("th", null, "Clearance"),
                _createElementVNode("th")
              ])
            ], -1)),
            (_ctx.data)
              ? (_openBlock(), _createElementBlock("tbody", _hoisted_11, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.users, (dt, index) => {
                    return (_openBlock(), _createBlock(_component_UserRow, {
                      key: index,
                      dt: dt
                    }, null, 8, ["dt"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.data)
            ? (_openBlock(), _createBlock(_component_Pagination, {
                key: 0,
                modelValue: _ctx.data.meta.page,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.data.meta.page) = $event)),
                currentPage: _ctx.data.meta.page,
                totalRecord: _ctx.data.meta.count,
                dataCount: _ctx.data.meta.per_page,
                onUpdated: _ctx.RefreshData
              }, null, 8, ["modelValue", "currentPage", "totalRecord", "dataCount", "onUpdated"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}